import firebase from 'firebase/app';
import "firebase/storage";
import "firebase/firestore";
require('dotenv').config();


const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,      
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,      
        projectId: process.env.REACT_APP_PROJECT_ID,      
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,               messagingSenderId: process.env.REACT_APP_MESSAGING_SENDE_ID,      
        appId: process.env.REACT_APP_APP_ID 
};

firebase.initializeApp(firebaseConfig);



export const projectStorage = firebase.storage();
export const projectFirestore = firebase.firestore();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export default firebase;
