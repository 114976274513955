import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useV3Firestore = (collection) => {
  
  const [carsList, setCarsList] = useState([]);
  
  //const [taxData, setTaxData] = useState([]);
  
// Для получения и отображения данных, компонент должен иметь поле
// valueShiftEndDate, содержащее отметку времени создания записи
  useEffect(() => {

    let ubsubscribeShifts = firebase.firestore().collection(collection)   
    .orderBy('cost', 'desc')    
    .onSnapshot((snap) => {
         const data = snap.docs.map( doc => {
           return {
              docID : doc.id,
              ...doc.data()
           };
         });
        
       // setTaxData(data);
        setCarsList(data);
          //setData(data);
    });
    return () => { 
     ubsubscribeShifts();
     };

  }, [collection]);
  return {   
  //taxData,
  carsList,
   // Data,
    
  };
};
export default useV3Firestore; 


