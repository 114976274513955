import React from 'react';
import { Alert } from 'react-bootstrap';
import c from './alert_msg.module.css';

const AlertOnAction = ( { variant, header }) => {
  return (
    <>
        <Alert variant={variant ? variant : 'success'} >
            <span className={c.alert}>
                {header}
            </span>
        </Alert>
    </>
  )
}

export default AlertOnAction