import React from 'react';
import c from '../Components/rotateLtr.module.css';
import { ArrowDownCircle } from './Icons';
import { goToCars } from './Functions';

const CircularText = () => {


  return (
  <div className={c.rot_box_wrapper}>
   <div className={c.rotate}>
    <span className={c.char1}>е</span>
    <span className={c.char2}>к</span>
    <span className={c.char3}>о</span>
    <span className={c.char4}>л</span>
    <span className={c.char5}>о</span>
    <span className={c.char6}>г</span>
    <span className={c.char7}>і</span>
    <span className={c.char8}>ч</span>
    <span className={c.char9}>н</span>
    <span className={c.char10}>і</span>
    <span className={c.char11}>с</span>
    <span className={c.char12}>т</span>
    <span className={c.char13}>ь</span>
    

    <span className={c.char21}>в</span>
    <span className={c.char22}>и</span>
    <span className={c.char23}>с</span>
    <span className={c.char24}>о</span>
    <span className={c.char25}>к</span>
    <span className={c.char26}>і</span>
    <span className={c.char27}> </span>
    <span className={c.char28}>с</span>
    <span className={c.char29}>т</span>
    <span className={c.char210}>а</span>
    <span className={c.char211}>т</span>
    <span className={c.char212}>к</span>
    <span className={c.char213}>и</span>
   

    <span className={c.char31}>ч</span>
    <span className={c.char32}>и</span>
    <span className={c.char33}>с</span>
    <span className={c.char34}>т</span>
    <span className={c.char35}>е</span>
    <span className={c.char36}></span>
    <span className={c.char37}>д</span>
    <span className={c.char38}>о</span>
    <span className={c.char39}>в</span>
    <span className={c.char310}>к</span>
    <span className={c.char311}>і</span>
    <span className={c.char312}>л</span>
    <span className={c.char313}>л</span>
    <span className={c.char314}>я</span>
    

    <span className={c.char41}>б</span>
    <span className={c.char42}>е</span>
    <span className={c.char43}>с</span>
    <span className={c.char44}>п</span>
    <span className={c.char45}>е</span>
    <span className={c.char46}>ч</span>
    <span className={c.char47}>н</span>
    <span className={c.char48}>і</span>
    <span className={c.char49}></span>
    <span className={c.char410}>а</span>
    <span className={c.char411}>в</span>
    <span className={c.char412}>т</span>
    <span className={c.char413}>о</span>   
  </div>
    <div className={c.arr_dwn_crcl}>
      <span onClick={() => goToCars(1, 0.055)}>
        {ArrowDownCircle(70)}
      </span>
    </div> 
  
 </div>
  )
}

export default CircularText

//