import React, { useState } from 'react';
import c from './Form.module.css';
import btn from './ModalCall.module.css';
import Captcha from '../Captcha/Captcha';
import { Form, FormControl, Button } from 'react-bootstrap';
import AlertMsg from './AlertMsg';
import axios from 'axios';

const ContactForm = () => {

 const [notChecked, setNotChecked] = useState(true);
 const [btnText, setBtnText] = useState('Для відправки - Заповніть поле Капча');
 const [btnTextSmall, setBtnTextSmall] = useState('Заповніть поле Капча');
 const [btnColor, setBtnColor] = useState('outline-light');

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [tel, setTel] = useState('');
 const [message, setMessage] = useState('');

 const [alertError, setAlertError] = useState(false);
 const [alertSending, setAlertSending] = useState(false);
 const [alertSent, setAlertSent] = useState(false);

const dateNow = new Date();
const dayNow = dateNow.getDate();
const monthNow = dateNow.getMonth();
const yearNow = dateNow.getFullYear();
const hourNow = dateNow.getHours();
const minNow = dateNow.getMinutes();
const dayHour = `${dayNow}-${monthNow}-${yearNow} ${hourNow}:${minNow}`;
const disableBtn = () => {
    setNotChecked(true);
    setBtnColor('outline-light');
    setBtnText('Для відправки - Заповніть поле Капча');
    setBtnTextSmall('Заповніть поле Капча');
    setTimeout(() => {
        setName('');
        setEmail('');
        setTel('');
        setMessage('');
    }, 2000);
    
 };

 const allowSending = (data) => {
     if (data === false) {
         setNotChecked(false);
         setBtnText('Відправити');
         setBtnTextSmall('Відправити');
         setBtnColor('outline-success');
     } else if (data === true) {
        disableBtn();
     }
    
 };

const subject = 'Message from site';

// eslint-disable-next-line
const handleSubmit = async (event) => {
    event.preventDefault();
    setAlertError(false);
    if (name === '' || tel === '' || message === '') {
        setAlertError('Будь ласка, заповніть усі поля.');
        setTimeout(() => {
            setAlertError(false);
        }, 2000);
        return;
    } else if (alertError === false) {
        setAlertError(false);
        setAlertSending(true);
     try {
        let data = {
            subject,
            dayHour,
            name,
            email,
            tel,
            message
        };
        console.log(data);
        const res = await axios.post('/contact', data);
        setAlertSending(false);
        setAlertSent(res.data.msg);
        setTimeout(() => {
            disableBtn();
            setAlertSent(false);
        }, 3500);
     } catch (err) {
        setAlertSending(false);
        console.log(err);
        setAlertError(err.data.msg);
        setTimeout(() => {
            setAlertError(false);
        }, 3000);
     }
    }
   
 };

 

  return (
  <Form 
    //onSubmit={(event) => handleSubmit(event)}
    action="feedback.php" 
    method="post"
    >
    <div className={c.form_container}>
        <div className={c.form_top_portion}>
            <div className={c.form_col1}>
                                         
              <FormControl 
                  placeholder="Ваше ім'я:" 
                  name="name"                 
                  value={name} 
                  type="text"
                  className={c.form_control}
                  onChange={(event) => {setName(event.target.value)}}
              /> 
               <div className={c.under_line}/>
               <FormControl 
                  placeholder="Ваш Email:" 
                  name="email"
                  value={email} 
                  type="email"
                  className={c.form_control}
                  onChange={(event) => {setEmail(event.target.value)}}
              /> 
               <div className={c.under_line}/>
              <FormControl 
                  placeholder="38(__)-__-_-_" 
                  name="tel"
                  value={tel} 
                  type="number"
                  className={c.form_control}
                  onChange={(event) => {setTel(event.target.value)}}
              /> 
               <div className={c.under_line}/>
               
            </div>
            <div className={c.form_col2}>
             <FormControl 
                   placeholder="Ваше питання:" 
                   name="message"
                   value={message} 
                   as="textarea" 
                   rows={4}
                   type="text"
                   className={c.form_control}
                   onChange={(event) => {setMessage(event.target.value)}}
              /> 
              
            <div className={c.under_line}/>
            </div>
        </div>

         <div className={`mt-3 ${c.form_lower_portion}`}>
             <div className='w-100'>
                {alertError && <AlertMsg 
                  variant={'danger'}
                  text={alertError}
                /> }
                 {alertSending &&<AlertMsg 
                  variant={'info'}
                  text={'Надсилаю Ваше повідомленя.'}
                />}
                 {alertSent && <AlertMsg 
                  variant={'success'}
                  text={alertSent}
                />}
             </div>
            <div className='w-100'>
                <Captcha 
                size={32}
                action={allowSending}
                />
                
            </div>
            <Button 
                variant={btnColor}
                id='send-btn-modal'
                type='submit'
                // onClick={disableBtn}
                className={`mb-4 mt-3 ${c.rounded_btn}`}
                disabled={notChecked}
            >
                <span className={btn.btn_text}>{btnText}</span>
                <span className={btn.btn_text_small}>{btnTextSmall}</span>
            </Button>
        </div>

    </div>
    </Form>
  )
}

export default ContactForm