import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from './Auth/PrivateRoute';
import { AuthProvider } from './Context/AuthContext';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import ForgotPassword from './Auth/ForgotPassword';
import MainScreen from "./Pages/MainScreen";
import UtilityPage from './Pages/UtilityPage';
import ThankYou from './Pages/ThankYou';
import NoPage from './Pages/NoPage';



function App() {
  return (
     <div className="App">           
      <Router>
       <AuthProvider>
          <Routes>
               <Route path='/' exect element={ <MainScreen/> } /> 
               <Route path='/thankyou' element={ <ThankYou/> } /> 
               <Route path='/utilityroom' element={
                <PrivateRoute>
                  <UtilityPage />
                </PrivateRoute>
                } /> 

               <Route path='/login' element={ <Login/> } />
               <Route path='/logout' element={ <Logout/> } />
               <Route path='/forgot_password' element={ <ForgotPassword/> } />

               <Route path='*' element={ <NoPage/> } />
          </Routes> 
        </AuthProvider>
      </Router>  
     </div>
  );
}

export default App;
