import React, { useState, useEffect } from 'react';
import c from './Captcha.module.css';
import { Image, Button } from 'react-bootstrap';
import bg5 from '../pic/captchabg5.jpg';
import { goBackIcon } from '../Components/Icons';
import { FormControl } from 'react-bootstrap';
//import CircularText from '../Components/CircularText';

const Captcha = ( { action, size }) => {

    const [captchaValue, setCaptchaValue] = useState('');
    const [enteredValue, setEnteredValue] = useState('');
    const [checkValue, setCheckValue] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    
    const createNewCaptcha = () => {
        let repNum = 5; 
        let newNum = Math.random().toFixed(1) * 10;
        if (newNum !== 0 && newNum !== 10) {
            repNum = newNum;
        }  
        let newValue = Math.random().toString(36).replace(/[^a-z]+/g, repNum).substr(0, 6);
        //let newValue = Math.random().toFixed(6) * 1000000;
        setCaptchaValue(newValue);
        setEnteredValue('');       
    };
    useEffect(() => {
        createNewCaptcha();
    }, []);
    ///////////
   
    /////////////
    const changeValue = (event) => {              
        setEnteredValue(event.target.value);        
        if (enteredValue.length === captchaValue.length - 1) {           
            setCheckValue(!checkValue);
        setTimeout(() => {           
            createNewCaptcha();           
        }, 500);
        setTimeout(() => { 
            if (action) {
                action(true);
                setIsVisible(true);  
            } 
        }, 35000);
        }  
    };

    
   
    useEffect(() => {
       if (enteredValue !== '') {
            if (enteredValue === captchaValue) {
               if (action) {
                   action(false); 
                   setIsVisible(false);
               } else {
                   alert('Yoohoo!');
               }             
            } else {
                alert('Wrong Code Entered');
            }
       } else {return;}
       // eslint-disable-next-line
    }, [checkValue]);

   // ml-1 mt-2
  return (
    <>
     
    <>
    <div className={c.captcha_container}>
        
      <div className={`mb-2 ${c.captcha_wrapper}`}>

        <div className={c.captcha_img_box}>
          
          <>
            <Image src={bg5} fluid rounded/>
            <div className={` ${c.captcha_txt}`}>            
              {isVisible && captchaValue}
            </div>
          </>
          
        </div>
        
        <div className={c.input_container} >         
          <FormControl            
             className={c.value_input}
             disabled={!isVisible} 
             placeholder='Введить символи з КАПЧА тут'
             value={enteredValue} 
             onChange={(event) => changeValue(event)}
            />
            <div className={c.under_line}/>
         
          <Button 
               className={`mt-3 ${c.renew_btn}`} 
               variant='outline-secondary'  
               onClick={() => createNewCaptcha()}
          >
            {goBackIcon(size)}
          </Button>
          
        </div>
       
      </div>

    </div>
    </>
   </>
  )
}

export default Captcha

 // eslint-disable-next-line
 {/* <button className={`ml-2 ${c.go_btn}`} onClick={() => trigerCheck()}>
             <span className='mb-1' >{IconArrowRightCircle()} </span> 
          </button>   */}
 // eslint-disable-next-line
        {/* <div className={c.ballast} >
            
        </div> */}

        // const trigerCheck = () => {
        
        // };
       // onKeyPress={(event) => keyPressed(event)} 
        // const keyPressed = (event) => {
        //     if (enteredValue.length > 3 && event.key === 'Enter') {
        //         trigerCheck();
        //     }
        // };
        // eslint-disable-next-line
          {/* <Button 
             className={`ml-2 mb-2 ${c.go_btn}`} 
             variant='outline-success' 
             disabled={disableBtn} 
             onClick={() => trigerCheck()}>
                 {IconCheck()}
          </Button> */}