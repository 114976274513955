import React, { useState }  from 'react';
import c from './RentConditions.module.css';
import {
    Container,    
    Modal,
    Button    
  } from 'react-bootstrap';
import ModalHeader from './ModalHeader';
import Agreement from '../pic/Agreement.pdf';

const RentContract = () => {

    const [showModal, setShowModal] = useState(false);
const openModal = () => {
    setShowModal(true);
  };
const hideModal = () => {   
    setShowModal(false);  
  };

  return (
    <>
        <span className={`ml-1 ${c.ini_text}`}  onClick={openModal} >
            Т У Т
        </span>

        <Modal
      className={c.bg}
      show={showModal}
      onHide={hideModal}          
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
      >
      <ModalHeader 
        title={'Договір про надання послуг'}
       />          
       <Modal.Body  className={c.bg}>          
         <Container className={c.modal__form}>
          <div className={c.under_line}/>
               <div className='mx-5'>
                  <br/>
                  <br/>
                  Сервіс «S&N», в подальшому «Виконавець», з одного боку, та<br/>
                  ФІЗИЧНА ОСОБА, яка на оплатній основі замовляє послуги сервісу «S&N», 
                  в подальшому «Замовник», що надалі разом іменуються «Сторони», 
                  а кожна окремо — «Сторона», уклали цей Договір про надання послуг (далі — «Договір») про наступне:<br/>                                                 
                   <b>1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</b><br/>
                   1.1. Сторони погодили, що терміни, як використовуються в Договорі, визначаються наступним чином:<br/>
                   «Виконавець» — фізична особа-підприємець та/або юридична особа, 
                   яка зареєстрована відповідно до вимог чинного законодавства України та здійснює свою діяльність як сервіс «S&N».<br/>
                   «Замовник» — фізична особа, яка володіє повною дієздатністю, віком не менше 25 років, має громадянство України, 
                   має право керувати транспортними засобами відповідної категорії (посвідчення водія) та стаж водіння не менше 3 років.<br/>
                   1.2. Даний Договір є договором приєднання відповідно до чинного законодавства України (ст. 634 Цивільного кодексу України). 
                   Даним Договором визначаються порядок та умови надання Виконавцем послуг Замовнику.<br/>
                   1.3. Свідченням повного та безумовного акцепту (прийняття) умов даного Договору, а також датою укладання даного Договору, 
                   є факт та дата підписання Замовником бланку замовлення послуг.<br/>
                   1.4. Сторони гарантують, що кожна зі Сторін володіє необхідною дієздатністю, всіма правами і повноваженнями, 
                   необхідними і достатніми для укладання та виконання даного Договору відповідно до його умов.<br/>
                   <b>2. ПРЕДМЕТ ДОГОВОРУ</b><br/>
                   2.1. За цим Договором Виконавець зобов’язується надати Замовнику інформаційні послуги з ефективної експлуатації електромобіля, 
                   відповідно до підписаного Замовником бланку замовлення послуг, в установлені строки та порядку, визначеному цим Договором, 
                   іншими внутрішніми стандартами, інструкціями та положеннями Виконавця, чинним законодавством України, 
                   а Замовник в свою чергу зобов’язується оплачувати надані послуги, відповідно до умов бланку замовлення послуг та даного Договору.<br/>                               
                   2.2. Шляхом підписання бланку замовлення послуг Замовник підтверджує, 
                   що ознайомився з умовами даного Договору та надає свою добровільну згоду на виконання його умов.<br/>
                   <b>3. ЗОБОВ’ЯЗАННЯ СТОРІН</b><br/>
                   3.1.  Виконавець зобов'язується:<br/>
                   - здійснювати надання послуг Замовнику на умовах, визначених укладеним Договором;<br/>
                   - надавати Замовнику додаткову інформацію пов’язану з наданням послуг.<br/>
                   3.2. Виконавець має право:<br/>
                   - вчасно та у повному обсязі отримувати оплату за Договором;<br/>
                   - відмовитися від цього Договору шляхом його дострокового розірвання і вимагати повернення ТЗ, відповідно до умов цього Договору;<br/>
                   - вимагати відшкодування завданих йому з вини Замовника збитків.<br/>                               
                   3.3. Замовник зобов’язується:<br/>
                   - своєчасно сплачувати Виконавцю грошові кошти за даним Договором;<br/>
                   - забезпечувати Виконавця інформацією, необхідною для надання послуг;<br/>
                   - повністю та належним чином відповідати змісту пункту 1.1 цього Договору.<br/>
                   3.4. Замовник має право:<br/>
                   - вчасно та у повному обсязі одержувати від Виконавця послуги, надання яких передбачено цим Договором;<br/>
                   - вчасно та у повному обсязі одержувати від Виконавця інформацію, яка потрібна для ефективного використання послуг Замовником.<br/>
                   <b>4. РОЗРАХУНКИ ЗА ДОГОВОРОМ</b><br/>
                   4.1. Розмір плати та порядок розрахунків встановлюється в бланку замовлення послуг.<br/>                               
                   4.2. Розрахунки між Сторонами за цим Договором здійснюються у грошовій одиниці України – гривні.<br/>
                   4.3. Оплата за Договором здійснюється в формі не забороненій чинним законодавством України.<br/>
                   <b>5. ВІДПОВІДАЛЬНІСТЬ СТОРІН</b><br/>
                   5.1. За невиконання або неналежне виконання зобов'язань за Договором Сторони 
                   несуть відповідальність відповідно до норм чинного законодавства України та Договору.
                    Сторона, що порушила свої зобов'язання за Договором повинна негайно усунути ці порушення.<br/>
                    5.2. У випадку порушення Замовником порядку та строків оплати (не оплати,  не повної оплати), 
                    та/ або інших умов бланку замовлення послуг, та за інші дії, 
                    що спричиняють шкоду (збитки) Виконавцю (далі – порушення), 
                    Замовник відшкодовує спричиненні ним Виконавцю збитки у повному обсязі 
                    та сплачує штраф у розмірі 3000,00 грн. за кожне виявлене порушення. 
                    Крім того, Виконавець в такому випадку має право розірвати даний Договір достроково з власної ініціативи.<br/>
                    5.3. Усі суперечки, які можуть виникнути з Договору, Сторони вирішують шляхом проведення переговорів. 
                    Суперечки, не врегульовані шляхом переговорів розглядаються в суді відповідно до чинного законодавства України.<br/>
                    <b>6. СТРОК ДІЇ ТА УМОВИ РОЗІРВАННЯ ДОГОВОРУ</b><br/>
                    6.1. Даний Договір набирає чинності з моменту підписання Замовником бланку замовлення послуг та діє до повного виконання Сторонами взятих на себе зобов’язань.<br/>
                    6.2. Сторони мають право на дострокове розірвання даного Договору у тому числі шляхом односторонньої відмови, 
                    в порядку та на підставах, встановлених цим Договором.<br/>
                    6.3. Виконавець у будь-який час має право достроково розірвати даний Договір з власної ініціативи, 
                    повідомивши про це Замовника у будь-який зручний для нього спосіб. 
                    При цьому Замовник зобов’язаний виконати усі вимоги Виконавця в наслідок розірвання даного Договору 
                    не пізніше ніж протягом двох годин з моменту отримання відповідної вимоги. 
                    В цей же строк Сторони проводять взаємний розрахунок.    <br/>                               
                    6.4. Замовник має право достроково розірвати даний Договір за умови обов’язкового попереднього повідомлення 
                    Виконавця не менш як за  3 (три) календарні дні до бажаної дати розірвання. 
                    У випадку дострокового розірвання Договору за ініціативою Замовника він зобов’язаний виконати усі вимоги Виконавця 
                    в наслідок розірвання даного Договору в обумовлений Сторонами строк. 
                    В цей же строк Сторони проводять взаємний розрахунок. 
                    У випадку порушення Замовником обов’язку попереднього повідомлення Виконавця не менш як 
                    за  3 (три) календарні дні до бажаної дати розірвання – Виконавець стягує з Замовника платню за такі 3 (три) календарні дні 
                    з розрахунку пропорційному до розміру плати погодженому  Сторонами відповідно до п. 4.1 даного Договору.<br/>
                    6.5. У випадках, не передбачених Договором, відносини Сторін регулюються чинним законодавством України.<br/>
                   
               </div>
          <div className={`my-3 ${c.under_line}`}/>
         </Container>
       </Modal.Body> 
       <Modal.Footer className={c.bg}>
        <a href='Agreement.pdf' download={Agreement}>
            <Button  
               variant="outline-light" 
               className={` ${c.rounded_btn}`}
               onClick={hideModal}
            >
              Завантажити у PDF
            </Button>
        </a>
            

            <Button  
               variant="outline-light" 
               className={` ${c.rounded_btn}`}
               onClick={hideModal}
            >
              Зрозуміло
            </Button>
       </Modal.Footer>    
     </Modal> 
    
    </>
  )
}

export default RentContract