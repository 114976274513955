import { Image } from 'react-bootstrap';
import c from './css/Screen_two.module.css';
import Img from '../pic/p2_img.png';
import img1 from '../pic/p2_imgroup2_1.svg';
import img2 from '../pic/p2_imgroup2_2.svg';
import img3 from '../pic/p2_imgroup2_3.svg';
import { useNavigate } from 'react-router-dom';




const ScreenOne = () => {

const navigate = useNavigate();
   
    return (
      <>
          
       <div className={`pb-5 ${c.screen_two}`}>
           <div className={`pb-5 ${c.screen_two_page}`}>
                <div className={c.screen_two_img_box}>
                        <Image src={Img} className={c.p2_img} fluid/>                 
                </div>

                <div className={c.screen_two_text_box}>
                    <div className={`${c.p_two_text_large} ${c.biger_line_h}`} onClick={() => navigate('/utilityroom')}>
                      Наша місія та цінності
                    </div>
                    <div className={c.p_two_text_normal}>
                        Ми, це небайдужі Одесити, які докладають зусиль до <br/>
                        популяризації електромобилів в нашому місти та в Україні в <br/>
                        цілому.<br/>
                        Наша мета - чисте повітря в нашому городі та беспечні авто на<br/>
                        його дорогах.
                    </div>
                </div>
           </div>

           <div className={`pb-5 ${c.screen_two_part_two}`}>
             <div className={c.screen_two_part_two_img_box}>
              
                   <Image src={img1} fluent className={c.item3} /> 
                 {/* <div className={c.img_subdivision}> </div> */}
                     <Image src={img2} fluent className={` ${c.item1}`} />
                     <Image src={img3} fluent className={c.item2} />
                
              
                   {/* <div className={`${c.three_img_box_item_one} ${c.three_img_box_item} `}/>
                   <div className={`${c.three_img_box_item} ${c.three_img_box_item_two}`}/>
                   <div className={`${c.three_img_box_item} ${c.three_img_box_item_three}`}/>
              */}
             </div> 
                {/* <div className={c.screen_two_img_box}>
                        <Image src={Img} className={c.p2_img} fluid/>                 
                </div>

                <div className={c.screen_two_text_box}>
                    <div className={c.p_two_text_large}>
                    Наша місія та цінності
                    </div>
                    <div className={c.p_two_text_normal}>
                        Ми, це небайдужі Одесити, які докладають зусиль до <br/>
                        популяризації електромобилів в нашому місти та в Україні в <br/>
                        цілому.<br/>
                        Наше мета - чисте повітря в нашому городі та беспечні авто на<br/>
                        його дорогах.
                    </div>
                </div> */}
           </div>
          

       </div>
        
        </>
    )
}

export default ScreenOne

