import React from 'react';
import c from './MediaIcons.module.css';
import { Whatsapp, Telegram } from '../Components/Icons';


const MediaIcons = () => {
  return (
         <div className={`${c.icons_container}`}>
            <a href="https://api.whatsapp.com/send?phone=380963420142" target="_blank" rel="noreferrer">
              <abbr title="Надішлить нам повідомленя у WhatsApp">
               {Whatsapp(36)}
              </abbr>        
            </a>  
            
            <a href="http://t.me/+380963420142" target="_blank" rel="noreferrer">
              <abbr title="Надішлить нам повідомленя у Telegram">
                <span className='mx-5 '>{Telegram(36)}</span>
              </abbr>                
            </a>
            
            <a href="viber://add?number=380963420142" rel="noreferrer" className={`${c.viber_icon}`}>
                 <i className={`fab fa-viber contacts-icons  ${c.viber_icon}`} title="Надішлить нам повідомленя у Viber"/>
            </a>
         </div>
  )
}

export default MediaIcons