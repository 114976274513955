import React, { useState }  from 'react';
import c from './ModalCall.module.css';
import btn from './btn.module.css';
import {
    Container,    
    Modal,
    Button,
    Form,   
    FormControl
  } from 'react-bootstrap';
import ModalHeader from './ModalHeader';
import axios from 'axios';
import Captcha from '../Captcha/Captcha';
import AlertMsg from './AlertMsg';
import $ from 'jquery';

//require('dotenv').config();

const ModalCall = (props) => {

    //const collection =`call_requests`;

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');

///////////// Captcha block ///////////////////////////
    const [notChecked, setNotChecked] = useState(true);
    const [btnText, setBtnText] = useState('Для відправки - Заповніть поле Капча');
    const [btnTextSmall, setBtnTextSmall] = useState('Заповніть поле Капча');
    const [btnColor, setBtnColor] = useState('outline-light');

    const disableBtn = (event) => {
       setNotChecked(true);
       setBtnColor('outline-light');
       setBtnText('Для відправки - Заповніть поле Капча');
       setBtnTextSmall('Заповніть поле Капча');
       setTimeout(() => {
           setName('');
           setTel('');
           setMessage('');
       }, 2000);
       
    };

    const allowSending = (data) => {
      if (data === false) {
          setNotChecked(false);
          setBtnText('Замовити дзвінок');
          setBtnTextSmall('Відправити');
          setBtnColor('outline-success');
      } else if (data === true) {
         disableBtn();
      }
     
  };
/////////////
const dateNow = new Date();
const dayNow = dateNow.getDate();
const monthNow = dateNow.getMonth();
const yearNow = dateNow.getFullYear();
const hourNow = dateNow.getHours();
const minNow = dateNow.getMinutes();
const dayHour = `${dayNow}-${monthNow}-${yearNow} ${hourNow}:${minNow}`;

const [showModal, setShowModal] = useState(false);

const [alertError, setAlertError] = useState(false);
const [alertSending, setAlertSending] = useState(false);
const [alertSent, setAlertSent] = useState(false);

const [messageSent, setMessageSent] = useState(false);

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
  setName('');
  setTel('');
  setMessage('');
  setShowModal(false);
};

const openModal = () => {
  setShowModal(true);
};

const subject = props.subject;

// eslint-disable-next-line
const handleCallReq = async (event) => {
  event.preventDefault();
  setAlertError(false);
  if (name === '' || tel === '' || message === '') {
      setAlertError('Будь ласка, заповніть усі поля.');
      setTimeout(() => {
          setAlertError(false);
      }, 2000);
      return;
  } else if (alertError === false) {
      setAlertError(false);
      setAlertSending(true);
   try {
      let data = {
          subject,
          dayHour,
          name,
          tel,
          message
      };
      console.log(data);
      const res = await axios.post('/contact', data);
      setAlertSending(false);
      setAlertSent(res.data.msg);
      setTimeout(() => {
          disableBtn();
          setAlertSent(false);
          hideModal();
      }, 3500);
   } catch (err) {
      setAlertSending(false);
      console.log(err);
      setAlertError(err.data.msg);
      setTimeout(() => {
          setAlertError(false);
      }, 3000);
   }
  }
 
};

// const submitForm = (e) => {
  
//   let data = {
//     subject,
//     dayHour,
//     name,
//     tel,
//     message
// };
// console.log(data);
// e.preventDefault();
//     axios({
//       method: "post",
//       url: `${process.env.REACT_APP_CALLBACK_API}`,
//       headers: { "content-type": "application/json" },
//       data: data
//     })
//       .then(result => {
//         if (result.data.sent) {
//           alert('Success!');          // setmailSent(result.data.sent)
//           // setError(false)
//         } else {
//           alert('Error');
//           //setError(true)
//         }

// })};
const handleSumbit = (e) => {
  e.preventDefault();
  const form = $(e.target);
  $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
          //setResult(data);
          console.log(data);
      },
  });
  setMessageSent(true);
  setTimeout(
    setMessageSent(false), 2000
  );
  setTimeout(  
    hideModal(), 3100
  );
  
};

  return (
    <>
    <Button  
         className={` ${btn.rounded_btn} ${btn.padding_txt2}`} 
         variant={'outline-light'}  
         onClick={openModal} 
       > 
          <span className={` ${btn.adaptiv_text_one}`}>
             {props.text ? props.text : (
               <>
                Передзвонить<span className={btn.invis_text}>_</span>мені
               </>
             )}
              
          </span>
        </Button>
    
    <Modal
      className={c.bg}
      show={showModal}
      onHide={hideModal}          
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
      >
      <ModalHeader 
        title={props.header}
       />          
       <Modal.Body  className={c.bg}>          
         <Container className={c.modal__form}>
           
            <Form 
              id="form"
              action={props.adress ? props.adress : 'callback.php'}               
              method="post"
              className={c.form}
              onSubmit={(event) => handleSumbit(event)}
              //  onSubmit={(event) => handleCallReq(event, props.path)}
              >
                         <FormControl  
                            name="time"                 
                            value={dayHour} 
                            type="hidden"                     
                          /> 
                  <div className={c.under_line}/>
              <FormControl 
                  placeholder="Ваше ім'я:" 
                  name="name"                 
                  value={name} 
                  type="text"
                  className={c.form_control}
                  onChange={(event) => {setName(event.target.value)}}
                  required
              /> 
              <FormControl 
                  placeholder="38(__)-__-_-_" 
                  name="tel"
                  value={tel} 
                  type="number"
                  className={c.form_control}
                  onChange={(event) => {setTel(event.target.value)}}
                  required
              /> 
              <FormControl 
                   placeholder="Коментарі (опціонально)" 
                   name="message"
                   value={message} 
                   as="textarea" 
                   rows={4}
                   type="text"
                   className={c.form_control}
                   onChange={(event) => {setMessage(event.target.value)}}                   
              />
               <div className={`mt-3 ${c.under_line}`}/>
               <div className='mt-2 w-100'>
               {alertError && <AlertMsg 
                  variant={'danger'}
                  text={alertError}
                /> }
                 {alertSending &&<AlertMsg 
                  variant={'info'}
                  text={'Надсилаю Ваше повідомленя.'}
                />}
                 {alertSent && <AlertMsg 
                  variant={'success'}
                  text={alertSent}
                />}
               </div>
              <div className='mt-3'>
              {messageSent ? <AlertMsg 
                  variant={'success'}
                  text={"Ваше повідомлення надіслано"}
                /> : 
                <Captcha 
                  size={32}
                  action={allowSending}               
                />}
              </div>  
              <div className={`mt-3 ${c.under_line}`}/>

              <div className={c.btm_btn_container}>
                <Button  
                 variant={"outline-secondary"} 
                 className={`mr-md-3 ${c.rounded_btn}`}
                 onClick={() => {hideModal()}}
                 >
                  С к а с у в а т и
                 </Button>

                 <Button 
                   variant={btnColor} 
                   className={`${c.rounded_btn}`}
                   //onClick={(e) => submitForm(e)}
                   type='submit'
                   disabled={notChecked}
                 >
                   <span className={c.btn_text}>{btnText}</span>
                   <span className={c.btn_text_small}>{btnTextSmall}</span>
                 </Button>            
               </div>
            </Form>
         </Container>
       </Modal.Body>       
     </Modal> 
    </>
  )
}

export default ModalCall