import React, { useRef, useState } from 'react';
import { Form, Button, Card, Container, Alert } from 'react-bootstrap';
import c from './Signup.module.css';
import { useAuth } from "../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Captcha from '../Captcha/Captcha';

export default function Login() {

    const emailRef = useRef();   
    const passwordRef = useRef();
   
    const { login } = useAuth(); // Можно убрать - currentUser
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [logingIn, setLogingIn] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        try{
            setError('');
            setLogingIn(true);
            await login(emailRef.current.value, passwordRef.current.value);
            navigate('/utilityroom');
        } catch {
            setLogingIn(false);
            setError('Помилка входу в обліковий запис користувача');
            console.log(error);
        }
        setLogingIn(false);
    }

    const action = (load) => {
        setLoading(load);
    };


    return (
        <>
        <Container fluid className={c.container} >
           
        <Card className={c.card}>
            <Card.Body>
                <h4 className="text-center mb-4" >
                  ВХІД В ЗАСТОСУНОК
                </h4>
                     {error && <Alert variant='danger'>{error}</Alert>}
                     {logingIn && 
                          <Alert variant='info'>
                            <b>Вхожу до облікового запису користувача</b>
                          </Alert>
                        }

                <Form onSubmit={handleSubmit}>
                     <Form.Group id="email">
                         <Form.Label >Email</Form.Label>
                         <Form.Control 
                            type="email" 
                            ref={emailRef}
                            required />
                     </Form.Group>

                    

                     <Form.Group id="password">
                         <Form.Label >Пароль</Form.Label>
                         <Form.Control 
                            type="password" 
                            ref={passwordRef}
                            required />
                     </Form.Group>
                     {loading && 
                        <Captcha 
                         action={action}
                         size={26}
                        />
                     }
                     
                        <br />
                        <br />                     
                     <Button disabled={loading} className="w-100" type="submit" variant={loading ? 'outline-light' : 'success'}>
                       {loading && 'Заповніть поле Капча'}
                       {!loading && ' УВІЙТИ'}                     
                     </Button>
                     <br/>
                     <Button variant='success' onClick={() => navigate('/')} className="mt-4 w-100">
                        На Головну
                     </Button>
                </Form>
            </Card.Body>     
          </Card>

          <div className="w-100 text-center mt-4" >
               Забули пароль? 
               <div className="mt-2">
                 <Link to="/forgot_password" className="mt-2">
                   Відновити пароль
                 </Link>
               </div>
           </div> 
           <br/>
           <br/>

        </Container>
        </>
    )
}

