import React from 'react';
import c from './card.module.css';
import a from '../Pages/Rides.module.css';
import ModalCarDetails from './ModalCarDetails';
import EditData from './EditData';


const UtilityCard = ( {model, img, images, id, color, year, bat, cost, details, fullDetails, docID, avail} ) => {
 //console.log(docID);
  return (
    <div className={c.card}>
        
    <div className={c.card_header}>
        <div className={c.card_img}>
             <img className={c.card_img_item} src={img} alt='car_photo'/>
        </div>                   
    </div> 

    <div className={c.card_header}>
     {model}<br/>
     BH {id}
    </div>

    <div className={`${c.card_body} `}>
     Колір: {color} <br/>
     <div className={c.under_line}/>
     Рік випуску: {year} <br/>
     <div className={c.under_line}/>
     Ємність батареї: {bat} kWt <br/>
     <div className={c.under_line}/>
     <div className={c.hide_overflow}>
        Вартість оренди від: {cost} гр/добу<br/>
     </div>
    
     <div className={c.under_line}/>
     <div>
       <div>
          Додатково: 
       </div>
       <div className={c.break_word}>
          {details}
       </div>
     </div>
    </div>

    <div className={c.card_footer}>
        <div className={` ${c.btn_cont_wrapper}`}>
           <div className={`${a.btn_container}`}>
             
                <ModalCarDetails 
                 header={'Детально про авто'}
                 id={id}
                 color={color}
                 year={year}
                 img={img}
                 images={images}
                 cost={cost} 
                 fullDetails={fullDetails}                  
             />
            
           </div>
           <div className={`pl-md-2 ${a.btn_container}`}>
          
             <EditData 
              cost={cost}  
              details={details}
              fullDetails={fullDetails} 
              docID={docID}
              avail={avail}                
             />
           
           </div>
         </div>
    </div>

</div>
  )
}

export default UtilityCard