import firebase from '../firebase';

export const goToCars = (screen, corr) => {
    let hight = screen * window.visualViewport.height + corr * window.visualViewport.height;
    //console.log(hight.toFixed(0));
    window.scrollTo({
        top: hight,
        behavior: 'smooth',
    });
};

export const editItemNoRef = (data, collection, docID) => {
    firebase.firestore().collection(collection)
    .doc(docID)
    .update(data);
};

export const addItemWithTrigers = (data, collection, docID, setAddingItem, setDoneAdding, setError) => {
    setAddingItem(true);
    firebase.firestore().collection(collection)
    .doc(docID)
    .update(data)                         
    .then(() => {
       setAddingItem(false);
       setDoneAdding(true);
      })                        
    .catch(() => {
       setAddingItem(false);
       setError(true);       
      });  
 };