import React from 'react';
import c from './css/Screen_Seven.module.css';
import MediaIcons from '../Components/MediaIcons';
// import { Image } from 'react-bootstrap';
// import ImgCar from '../pic/p7-img.svg';
import { exclamationTriangleIcon } from '../Components/Icons';
import ContactForm from '../Components/ContactForm';
import RentConditions from '../Components/RentConditions';
import RentContract from '../Components/RentContract';

const ScreenSeven = () => {


  return (
    <>
    
    <div className={` ${c.screen_seven}`}>
            <div className={c.screen_seven_text_box}>
                <div className={`${c.p_seven_text_large} ${c.biger_line_h}`}>
                 Залишилися питання?
                </div>
                <div className={c.p_seven_text_normal}>
                 Наші фахівці зв'яжуться з вами найближчим часом і проконсультують вас!
                </div> 
            </div>
                <div className={c.p_seven_form_container}>
                    <ContactForm />
              <div > 

                <div className='my-4'>
                  <MediaIcons/>
                </div> 
            <div className={c.footer_note_container}>
              
                  <div className={c.remark_sign1}>
                    {exclamationTriangleIcon(20)}
                  </div> 

                  <div className={` ${c.p_seven_text_normal} ${c.text_normal_2}`}>
                    <span className='mx-2'>
                        З умовами оренди, можно ознайомитись 
                    </span> <br/>
                     <RentConditions />
                   </div>

                   <div className={c.remark_sign2}>
                    {exclamationTriangleIcon(20)}
                  </div>      
            </div>

                  <div className={` mt-4 ${c.p_seven_text_normal} ${c.text_normal_2}`}>
                    <span className='mx-2'>
                        З Договором про надання послуг, можно ознайомитись 
                    </span> <br/>
                     <RentContract />
                  </div>
                 
                  <div className={c.lower_ballast} />
             </div>
            </div> 
          
             
            {/* <Image src={ImgCar} className={c.page_bottom_img} fluid/>    */}

    </div>
    
     </>
 )
}

export default ScreenSeven
