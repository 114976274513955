import { Form } from 'react-bootstrap';
import c from './alert_msg.module.css';




const RadioComp = (props) => {
    
    return (
        <>  
                <Form className={`mb-3 ${c.select_box}`}>
                  <div className={c.ballast}/>
                  <div key={`inline-'radio'`} >
                    <Form.Check
                      inline
                      label={props.lable1}
                      value={props.value1 && props.value1}
                      name="group1"
                      type='radio'
                      id={`inline-'radio'-1`}
                      onClick={props.action1}
                    />
                    
                    <Form.Check
                      inline
                      label={props.lable2}
                      value={props.value2 && props.value2}
                      name="group1"
                      type='radio'
                      id={`inline-'radio'-2`}
                      onClick={props.action2}
                    />
                   
                  </div>  
                </Form>
        </>
    )
}

export default RadioComp

