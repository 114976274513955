import React, { useState }  from 'react';
import c from './RentConditions.module.css';
import {
    Container,    
    Modal,
    Button    
  } from 'react-bootstrap';
import ModalHeader from './ModalHeader';

const RentConditions = ( { order } ) => {

const [showModal, setShowModal] = useState(false);
const openModal = () => {
    setShowModal(true);
  };
const hideModal = () => {   
    setShowModal(false);  
  };


  return (
    <>
       {order && (
         <span className={`ml-1 ${c.ini_text}`}  onClick={openModal} >
          Умови оренди Т У Т
         </span>
         
       )}
       {!order &&
          <span className={`ml-1 ${c.ini_text}`}  onClick={openModal} >
            Т У Т
          </span>
         }
    
    <Modal
      className={c.bg}
      show={showModal}
      onHide={hideModal}          
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
      >
      <ModalHeader 
        title={'Умови Оренди'}
       />          
       <Modal.Body  className={c.bg}>          
         <Container className={c.modal__form}>
          <div className={c.under_line}/>
               <div className='mx-5'>
                  <br/>
                  <br/>
                  - Транспортні засоби надаються в оренду громадянам України 
                  віком не молодше 25 років.<br/>
                  - Перед початком оренди, орендар має надати інформацію та документи, 
                  що підтверджують особу орендаря (паспорта, ідентифікаційний код) та право
                   особи керувати транспортними засобами відповідної категорії (посвідчення водія). <br/>
                   - Орендарю може бути відмовлено в надані послуг без пояснення причин.<br/>                               
                    - Транспортні засоби обладнані портом швидкої зарядки CHAdeMO. <br/>
                    - Разом з електромобілем надається зарядний пристрій для заряджання батареї транспортного 
                       засобу від побутової електромережі. <br/>
                    - Сума застави за оренду транспортного засобу становить від 6000 грн. <br/>
                    - Усі транспортні засоби застраховані по КАСКО та ОСАГО у провідних страхових компаніях України. <br/>
                    - У випадку настання ДТП за участю орендованого транспортного засобу, суму франшизи за страховими полісами 
                       та вартість шкоди, яка перевищує розмір страхової суми, сплачує орендар. Відповідальність за порушення правил 
                       дорожнього руху несе орендар. <br/>
                    - Вартість зарядки батареї транспортного засобу сплачує орендар. <br/>
                    - У вартість оренди включено відстань до 1200 км пробігу на тиждень (максимальний пробіг). 
                       За пробіг, який перевищує показники встановленого максимального пробігу, орендар сплачує додаткову плату. <br/>
                    - Отримати транспортний засіб можна в любий зручний час за попередньою домовленістю. <br/>
                    - Орендар повертає транспортний засіб в останній день оренди до часу доби в який він його отримав, але не пізніше 18:00. <br/>
                    - Можливість викупу транспортного засобу обговорюється окремо у кожному випадку. Вартість послуги розраховується 
                       виходячи з вартості транспортного засобу та терміну виплати.<br/>
                       <br/>
               </div>
          <div className={`my-3 ${c.under_line}`}/>
         </Container>
       </Modal.Body> 
       <Modal.Footer className={c.bg}>
            <Button  
               variant="outline-light" 
               className={` ${c.rounded_btn}`}
               onClick={hideModal}
            >
              Зрозуміло
            </Button>

            
       </Modal.Footer>    
     </Modal> 
      
    </>
  )
}

export default RentConditions