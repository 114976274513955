import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import c from './btn.module.css';
import { goToCars } from './Functions';
import ModalCall from './ModalCall';





const ActionButtons = () => {
  //console.log(window.visualViewport.width, window.visualViewport.height);

const [offSet, setOffSet] = useState(2.52);
useEffect(() => {
  if (window.visualViewport.width < 1015 && window.visualViewport.height < 595) {
    setOffSet(2.6);
   }
  if (window.visualViewport.width < 1007 && window.visualViewport.height < 590) {
    setOffSet(2.6);
   }
  if (window.visualViewport.width < 913) {
    setOffSet(2.51);
   }
   if (window.visualViewport.width < 902) {
    setOffSet(2.55);
   }
  if (window.visualViewport.width < 821) {
    setOffSet(2.89);
   }  
   if (window.visualViewport.width < 545 && window.visualViewport.height < 727) {
    setOffSet(3.7);
   }    
   if (window.visualViewport.width < 541) {
    setOffSet(3.2);
   }       
   if (window.visualViewport.width < 540 && window.visualViewport.height < 718) {
    setOffSet(3.75);
   }      
  if (window.visualViewport.width < 415) {
    setOffSet(3.48);
   }   
   if (window.visualViewport.width < 413 && window.visualViewport.height < 895) {
    setOffSet(3.44);
   }
   if (window.visualViewport.width < 413 && window.visualViewport.height < 915 && window.visualViewport.height > 905 ) {
    setOffSet(4);
   }
   if (window.visualViewport.width < 394) {
    setOffSet(3.53);
   }  
   if (window.visualViewport.width < 376) {
    setOffSet(3.9);
   }
   if (window.visualViewport.width < 361) {
    setOffSet(3.7);
   }
  if (window.visualViewport.width < 285) {
  setOffSet(3.9);
 }
}, []);  

  return (
    
      <div className={`${c.btn_cont_wrapper}`}>
        <div className={c.btn_container}>
          <a href='#cars'>   
            <Button  
                 variant={'outline-light'} onClick={() => goToCars(offSet, 0.055)} 
                 className={` ${c.rounded_btn} ${c.padding_txt}`}
              > 
              <span className={`${c.adaptiv_text_one}`}>
                Hаявні<span className={c.invis_text}>_</span>авто
              </span>
            </Button>
        </a>
        </div>
        <div className={c.btn_container}> 
            <ModalCall 
             header={'Дані для зворотнього дзвінку'}
             subject={'Call me back'}
            />
        </div>
      </div>
    
  )
}

export default ActionButtons


//onClick={() => link('available_cars')}
//   