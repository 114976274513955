import { Image } from 'react-bootstrap';
import c from './css/Screen_one.module.css';
import LeafPic1 from '../pic/Leaf-new1.svg';
import theaterImg from '../pic/p1-theatre.png';
import img41 from '../pic/img41.png';
import img42 from '../pic/img41_2.png';
//import Theatre from '../pic/p1-theatre.svg';
//import seagull1 from '../pic/seagull_1.svg';
//import seagull2 from '../pic/seagull_2.svg';

import Circuls from '../pic/Circuls.svg';
import ActionButtons from '../Components/ActionButtons';
import CircularText from '../Components/CircularText';



const ScreenOne = () => {

    return (
      <>
         
       <div className={`pb-5 ${c.screen_one}`}>
           <div className={c.theatre_div_bg}>
                <Image src={theaterImg} className={`${c.theatre_one}`} fluid/>
                <Image src={theaterImg} className={c.theatre_two} /> 
                <Image src={LeafPic1} className={c.car_one_img} fluid/>
                <Image src={img41} className={c.plant_one_img} fluid/>
                <Image src={img42} className={c.plant2_one_img} fluid/>

                  {/* <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/p1-theatre.svg?alt=media&token=8892a2cc-7a0b-402c-bd94-589c022576cd'} 
                        className={c.theatre_one} fluid/>  */}
                 {/* <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/p1-theatre.svg?alt=media&token=8892a2cc-7a0b-402c-bd94-589c022576cd'} 
                        className={c.theatre_two} />  */}
                 {/* <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/Leaf-new1.svg?alt=media&token=6378c601-7ea7-4c88-aac3-6339263067ca'} 
                        className={c.car_one_img} fluid/> */}
                 {/* <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/img41.svg?alt=media&token=c5c4e192-6324-4923-84b9-7664a84afde7'} 
                        className={c.plant_one_img} fluid/>
                 <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/img41_2.svg?alt=media&token=75253500-b155-45dc-8f72-46b83fa57a19'} 
                        className={c.plant2_one_img} fluid/> */}
                 <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/seagull_1.svg?alt=media&token=ac7aae6d-91aa-4caf-9711-92090293ed7b'} 
                        className={c.bird1_one_img} fluid/>
                 <Image src={'https://firebasestorage.googleapis.com/v0/b/rentacarodesa.appspot.com/o/seagull_2.svg?alt=media&token=402014b0-6917-4e27-a76d-decdee9379ca'} 
                        className={c.bird2_one_img} fluid/>
                 <Image src={Circuls} className={c.circuls_one_img} fluid/>

                 <div className={c.p_one_text_container}>
                   <div className={c.p_one_text_large}>                       
                        ОРЕНДА<br/> ЕЛЕКТРОМОБІЛЯ В <br/>ОДЕСІ - ЦЕ ПРОСТО!
                   </div>
                   <div className={c.p_one_text_normal}>
                     Це просто, надійно, та вигідно! <br/>
                     Оренда електромобіля в Одесі - це ми.
                   </div> 
                   <div className={c.sm_screen_gap} />
                   <div className={` ${c.p_one_btn}`}>
                        <ActionButtons />
                   </div> 
                 </div>
                 {/* <div className={c.circ_txt}>
                   <CircularText  />
                 </div> */}
           </div>
                 <div className={c.circ_txt}>
                   <CircularText  />
                 </div>
          

       </div>
        
        </>
    )
}

export default ScreenOne


//<i className='contacts-icons fab fa-whatsapp fa-lg  my-3' title="Отправьте сообщение в WhatsApp" /><span className='invisible'>-</span>WhatsApp

//<i className="fab fa-telegram fa-lg contacts-icons ml-3 ml-sm-4 my-4" title="Отправьте сообщение в Телеграм"/>
//<span className='invisible'>-</span>