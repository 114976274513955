import React, {useState, useEffect} from 'react';
import c from './css/Screen_Four.module.css';
import { Container, Button } from 'react-bootstrap';
import UtilityCard from '../Components/UtilityCard';
import useV3Firestore from "../Hooks/useV3Firestore";
import { useNavigate } from 'react-router-dom';
import GoUpBtn from '../Components/GoUpBtn';
import SpinnerBig from '../Components/SpinnerBig';


const UtilityPage = () => {

    const collection = 'cars';
    const {carsList} = useV3Firestore(collection); 
    const [cars, setCars] = useState([]);

useEffect(() => {
      if (carsList.length > 0) {
        setCars(carsList);
      }
}, [carsList]);
console.log(carsList);
const navigate = useNavigate();
  
    return (
      <div className={c.utilitypage_wrapper}>
      
      <Container fluid className={`mt-3`}>
         {cars.length > 0 ? <>
              <div className={c.screen_four_text_box}>
                  <div className={`my-4 ${c.p_four_text_large} ${c.biger_line_h}`}>
                    Усі авто
                  </div>     
              </div>
              
                <div className={c.card_container_utility_page}>
                  {cars.map((car) => 
                  
                    <UtilityCard
                      model={car.model} 
                      img={car.img[0]}
                      images={car.img}
                      id={car.id}
                      color={car.color}
                      year={car.year}
                      bat={car.bat}
                      cost={car.cost}
                      details={car.details}
                      fullDetails={car.fullDetails}
                      docID={car.docID}
                      avail={car.available}
                    />              
                  )}
                </div> 
                </> : 
                <SpinnerBig />
                
              }
           <div className={c.btn_container}> 
            <Button 
             onClick={() => navigate('/logout')} 
             variant='warning'
             className={` ${c.go_to_mp_btn}`}
             >
              LOG OUT
            </Button>      
            <Button 
              onClick={() => {navigate('/')}} 
              variant='info'
              className={` ${c.go_to_mp_btn}`}
            >
                На Головну
            </Button>
            
          </div> 
      </Container>
       <GoUpBtn />
       </div>
   )
  }

export default UtilityPage