import React, { useState }  from 'react';
import c from './ModalCall.module.css';
import cd from './CarDetails.module.css';
import btn from './btn.module.css';
import {
    Container,    
    Modal,
    Button,
    Image,
    Carousel, 
    CarouselItem   
  } from 'react-bootstrap';
import ModalHeader from './ModalHeader';
import ModalOrderForm  from './ModalOrderForm';
import RentConditions from './RentConditions';




const ModalCarDetails = (props) => {

const [showModal, setShowModal] = useState(false);
const openModal = () => {
    setShowModal(true);
  };
const hideModal = () => {   
    setShowModal(false);  
  };



  return (
    <>
      <Button  
         className={` ${btn.rounded_btn} `} 
         variant={'outline-light'}  
         onClick={openModal} 
       > 
          <span className={` ${btn.adaptiv_text_one}`}>
            Детально
          </span>
       </Button>
    
    <Modal
      className={c.bg}
      show={showModal}
      onHide={hideModal}          
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
      >
      <ModalHeader 
        title={props.header}
       />          
       <Modal.Body  className={c.bg}>          
         <Container className={c.modal__form}>
         <div className={c.under_line}/>
               <div className={cd.car_details_container}>
               <h4 className='mt-2'>NISSAN LEAF</h4> 
                 {props.images && 
                   <Carousel  className={`${cd.cd_carousel} d-block `}>
                    {props.images.map( (image, index) =>                     
                     <CarouselItem key={index} >                        
                         <Image key={index} src={image} fluent className={` ${cd.car_img}`} />
                        <Carousel.Caption >
                       </Carousel.Caption>
                     </CarouselItem>)}
                   </Carousel>
                   }
                 <div className={`${cd.cd_txt}`}>
                  {props.color && `Колір: ${props.color}`}<br/>                 
                  {props.year && `Ріку Випуску: ${props.year} `}<br/>
                  {props.fullDetails && props.fullDetails}<br/>
                  {props.cost && `Вартість оренди:  від ${props.cost} гр/добу за умови передплати за 30 діб`}
                </div>
                <br/>
                <RentConditions 
                 order={true}                
                />
               </div>
            <div className={`my-3 ${c.under_line}`}/>
         </Container>
       </Modal.Body> 
       <Modal.Footer className={`${c.bg} ${c.modal_footer_btn}`}>
            <Button  
               variant="outline-secondary" 
               className={`mr-md-3 ${c.rounded_btn}`}
               onClick={hideModal}
            >
              С к а с у в а т и
            </Button>

            <ModalOrderForm 
                   header={'Замовленя оренди автівки'}
                   id={props.id}
                   color={props.color}
                   year={props.year}
                   img={props.img}
                   cost={props.cost}                   
                  />
       </Modal.Footer>    
     </Modal> 
      
    </>
  )
}

export default ModalCarDetails

//<FooterButtons 
//     onCancel={hideModal}
//     onAccept={checkForm}
//     disabled={loadingImg}
//   />