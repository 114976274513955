import React, { useState }  from 'react';
import c from './ModalCall.module.css';
import btn from './btn.module.css';
import {
    Container,    
    Modal,
    Button,
    Form,   
    FormControl,
    Image   
  } from 'react-bootstrap';
import ModalHeader from './ModalHeader';
import Captcha from '../Captcha/Captcha';
import axios from 'axios';
import AlertMsg from './AlertMsg';
import RentConditions from './RentConditions';

const ModalOrderForm = (props) => {

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');   
    const id = props.id;

///////////// Captcha block ///////////////////////////
    const [notChecked, setNotChecked] = useState(true);
    const [btnText, setBtnText] = useState('Для відправки - Заповніть поле Капча');
    const [btnTextSmall, setBtnTextSmall] = useState('Заповніть поле Капча');
    const [btnColor, setBtnColor] = useState('outline-light');

    const [alertError, setAlertError] = useState(false);
    const [alertSending, setAlertSending] = useState(false);
    const [alertSent, setAlertSent] = useState(false);

const dateNow = new Date();
const dayNow = dateNow.getDate();
const monthNow = dateNow.getMonth();
const yearNow = dateNow.getFullYear();
const hourNow = dateNow.getHours();
const minNow = dateNow.getMinutes();
const dayHour = `${dayNow}-${monthNow}-${yearNow} ${hourNow}:${minNow}`;

    const disableBtn = (event) => {
       setNotChecked(true);
       setBtnColor('outline-light');
       setBtnText('Для відправки - Заповніть поле Капча');
       setBtnTextSmall('Заповніть поле Капча');
       setTimeout(() => {
           setName('');
           setTel('');
           setMessage('');
       }, 2000);       
    };

    const allowSending = (data) => {
      if (data === false) {
          setNotChecked(false);
          setBtnText('Замовити автівку');
          setBtnTextSmall('Відправити');
          setBtnColor('outline-success');
      } else if (data === true) {
         disableBtn();
      }
     
  };
///////////// End of Captcha block ///////////////////////////
const [showModal, setShowModal] = useState(false);
const openModal = () => {
    setShowModal(true);
  };
const hideModal = () => { 
    setName('');
    setTel('');
    setMessage('');  
    setShowModal(false);
  };

const subject = `I wish to order the Car - ${id}`;

// eslint-disable-next-line
const handleSubmit = async (event) => {
  event.preventDefault();
  setAlertError(false);
  if (name === '' || tel === '' || message === '') {
      setAlertError('Будь ласка, заповніть усі поля.');
      setTimeout(() => {
          setAlertError(false);
      }, 2000);
      return;
  } else if (alertError === false) {
      setAlertError(false);
      setAlertSending(true);
   try {
      let data = {
          subject,
          dayHour,
          name,
          tel,
          message
      };
      console.log(data);
      const res = await axios.post('/contact', data);
      setAlertSending(false);
      setAlertSent(res.data.msg);
      setTimeout(() => {
          disableBtn();
          setAlertSent(false);
          hideModal();
      }, 3500);
   } catch (err) {
      setAlertSending(false);
      console.log(err);
      setAlertError(err.data.msg);
      setTimeout(() => {
          setAlertError(false);
      }, 3000);
   }
  } 
};
 
   return (
    <>
    <Button  
         className={` ${btn.rounded_btn} `} 
         variant={'outline-light'}  
         onClick={openModal} 
       > 
          <span className={` ${btn.adaptiv_text_one}`}>
              Замовити
          </span>
        </Button>
    
    <Modal
      className={c.bg}
      show={showModal}
      onHide={hideModal}          
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
      >
      <ModalHeader 
        title={props.header}
       />          
       <Modal.Body  className={c.bg}>          
         <Container className={c.modal__form}>
         <div className={c.under_line}/>
               <div className={c.car_details_container}>
                  <Image src={props.img} fluent className={`mr-5 ${c.car_img}`} />
                  <div>
                    NISSAN LEAF <br/>
                  {props.color && props.color}<br/>                 
                  {props.year && `${props.year} року випуску`}<br/>
                  {props.cost && `Вартість оренди:`}<br/>
                  {props.cost && `від ${props.cost} гр/добу`}<br/>
                 
                     <RentConditions 
                      order={true}
                     />
                  </div>
                  
                    
               </div>
            <Form                
               className={c.form}
               action="order.php" 
               method="post" 
               //onSubmit={(event) => handleSubmit(event)}
               >
                  <div className={c.under_line}/>
                        <FormControl  
                            name="id"                 
                            value={id} 
                            type="hidden"                     
                        /> 
              <FormControl 
                  placeholder="Ваше ім'я:" 
                  name="name"                 
                  value={name} 
                  type="text"
                  className={c.form_control}
                  onChange={(event) => {setName(event.target.value)}}
                  required
              /> 
              <FormControl 
                  placeholder="38(__)-__-_-_" 
                  name="tel"
                  value={tel} 
                  type="number"
                  className={c.form_control}
                  onChange={(event) => {setTel(event.target.value)}}
                  required
              /> 
              <FormControl 
                   placeholder="Коментарі (опціонально)" 
                   name="message"
                   value={message} 
                   as="textarea" 
                   rows={4}
                   type="text"
                   className={c.form_control}
                   onChange={(event) => {setMessage(event.target.value)}}                   
              />
               <div className={`mt-3 ${c.under_line}`}/>
               <div className='mt-2 w-100'>
                {alertError && <AlertMsg 
                    variant={'danger'}
                    text={alertError}
                  /> }
                  {alertSending &&<AlertMsg 
                    variant={'info'}
                    text={'Надсилаю Ваше повідомленя.'}
                  />}
                  {alertSent && <AlertMsg 
                    variant={'success'}
                    text={alertSent}
                  />}
               </div>
              <div className='mt-3'>
                
              <Captcha 
               size={32}
               action={allowSending}               
              />
              </div>  
              <div className={`mt-3 ${c.under_line}`}/>

              <div className={c.btm_btn_container}>
                <Button  
                 variant={"outline-secondary"} 
                 className={`mr-md-3 ${c.rounded_btn}`}
                 onClick={() => {hideModal()}}
                 >
                  С к а с у в а т и
                 </Button>

                 <Button 
                   variant={btnColor} 
                   className={`${c.rounded_btn}`}
                   type='submit'
                   disabled={notChecked}
                 >
                  <span className={c.btn_text}>{btnText}</span>
                  <span className={c.btn_text_small}>{btnTextSmall}</span>
                 </Button>            
               </div>
              

            </Form>
            {/* {alertSuccess && 
               <Alert variant='success' >
                 <span className={c.alert}>
                   Заявка відправлена. Дякуємо!
                 </span>
                </Alert> 
              } */}

                {/* <AlertDangerTriangle 
                  notice={notice}
                  alertHeader={'Будь ласка, додайте потрібні дані'}
                 />
                */}
         </Container>
       </Modal.Body>       
     </Modal> 
    </>
  )
}

export default ModalOrderForm