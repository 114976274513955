import React from 'react';
import c from './css/Screens.module.css';
import ScreenOne from './ScreenOne';
import ScreenTwo from './ScreenTwo';
import ScreenThree from './ScreenThree';
import ScreenFour from './ScreenFour';
import ScreenSix from './ScreenSix';
import ScreenSeven from './ScreenSeven';
import GoUpBtn from '../Components/GoUpBtn';
import PhoneBtn from '../Components/PhoneBtn';


const MainScreen = () => {
     
  return (
    <div className={c.screen}>
      
        <section id='one'>
             <ScreenOne />
        </section> 
        <section id='two'>
             <ScreenTwo />
        </section> 
        <section id='three'>           
             <ScreenThree />
        </section> 
        <section id='cars'>
         <br/>
             <ScreenFour />
        </section>



        <section id='six'>
             <ScreenSix />
        </section> 
        <section id='seven'>
            <ScreenSeven />
        </section> 

        <GoUpBtn /> 
        <PhoneBtn /> 
        
    </div>
  )
}

export default MainScreen