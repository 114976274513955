import React from 'react';
import c from './ModalCall.module.css';
import {    
    Row,
    Col,
    FormControl,    
    InputGroup 
  } from 'react-bootstrap';
/////////////////////////////////// Этот компонент, это просто -
///////////////////////// кусок верстки, содержащий Bootstrap раскладку
/////////////////////// часто переиспользуемую в компоненте  AddNewRideModal
const ModalFormline = (props) => {
    return (
        <Row>
            <Col className="py-2" sm={12} md={6}>
              <h6 className={c.modal__form}>
                {props.header}
              </h6> 
            </Col>
            <Col  sm={12} md={6}>
              <InputGroup className="mb-4">    
                <FormControl 
                  value={props.value} 
                  onChange={event => props.onChange(event.target.value)} 
                  type={props.type} 
                  as={"textarea"} 
                  rows={props.rows}
                  disabled={props.disabled}
                 />
              </InputGroup>
            </Col>
          </Row>
    )
}

export default ModalFormline
