import React from 'react';
import {Button} from 'react-bootstrap';
import c from './GoUpBtn.module.css';
import { Phone } from './Icons.js';

const PhoneBtn = () => {
    
   
  return (
     
    <>
      <a href="tel:+380963420142" target="_blank" rel="noopener noreferrer">
        <Button 
            variant='outline-info' 
            className={c.sticky_phone_btn}           
         >
            <i title="Подзвонить мені | Call me">
                {Phone(36)}  
            </i>
        </Button>
      </a> 
    </>
    
  )
}

export default PhoneBtn