import React from 'react';
import { Alert } from 'react-bootstrap';
import {exclamationCircleIcon} from './Icons';

const AlertDangerRound = (props) => {
    return (
     <>
        {props.triger &&
        <Alert variant='danger'>
          {props.alertHeader}
           <span className="ml-5" >{exclamationCircleIcon(25)}</span>
        </Alert>
        }
     </>
    )
}

export default AlertDangerRound
