import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import c from './css/Screen_two.module.css';
import { Button } from 'react-bootstrap';
import Img from '../pic/p2_img.svg';
import { useNavigate } from 'react-router-dom';

const NoPage = () => {

const navigate = useNavigate();
useEffect(() => {
    setTimeout(() => navigate('/'), 12000);
// eslint-disable-next-line 
}, []);


  return (
    <div className={`pb-5 ${c.screen_two_thankyou}`}>
    <div className={`pb-5 ${c.screen_two_page}`}>
         <div className={c.screen_two_img_box}>
                 <Image src={Img} className={c.p2_img} fluid/>                 
         </div>

         <div className={c.screen_two_text_box_thankyou}>
             <div className={`${c.p_two_text_large} ${c.biger_line_h}`}>
              Пробачте.
             </div>
             <div className={c.p_two_text_normal}>
                Запитуєма сторінка не знайдена.<br/> 
               <Button 
                 onClick={() => {navigate('/')}} 
                 variant='outline-light'
                 className={`my-5 ${c.go_to_mp_btn}`}
                 >
                 На Головну
                </Button>                       
             </div>
             
         </div>
    </div>
</div>
  )
}

export default NoPage