import React from 'react';
import c from './css/Screen_Three.module.css';
import { Image } from 'react-bootstrap';
import ImgCar from '../pic/p3_img_car.svg';
import ActionButtons from '../Components/ActionButtons';


const ScreenThree = () => {
  return (
    <>
    
    <div className={`pb-5 ${c.screen_three}`}>
        <div className={` ${c.screen_three_bg_img}`}>
             <div className={c.screen_three_text_box}>
                 <div className={`${c.p_three_text_large} ${c.biger_line_h}`}>
                   Вже цікаво?
                 </div>
                 <div className={c.p_three_text_normal}>
                    Ми обираємо краще майбутне! Приєднуйся до нас!
                 </div>
                 <div className={c.btn_wrapper}>
                     <ActionButtons />
                 </div>
                 
             </div>
             <Image src={ImgCar} className={c.p3_img_car} fluid/> 
        </div>
    </div>
     
     </>
 )
}

export default ScreenThree
