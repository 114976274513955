import React from 'react';
import ModalCall from './ModalCall';
import c from './NoCar.module.css';

const NoCar = () => {
  return (
    <div className={c.wrapper}>
        Нажаль, в цей час не маємо жодної вільної автівки.<br/>
        Будь ласка, відправте нам свої контактні дані, <br/>
        та станьте першим хто дізнається про наявність вільного авто!<br/><br/>
        <span className={c.contrast_txt}>Приємний БОНУС!</span><br/>
        Bідправте нам свої контактні данні та <br/>
        перший день оренди, для Вас буде<br/>
        <span className={`mt-1 mb-2 ${c.contrast_txt}`}>БЕЗКОШТОВНИЙ!</span><br/>
        <ModalCall 
          header={'Повідомте мені про вільне авто'}
          text={'Хочу автівку'}
          subject={'Car Preorder'}
          adress={'preorder.php'}
        />
    </div>
  )
}

export default NoCar