import React from 'react';
import c from './css/Screen_Six.module.css';
import { Image } from 'react-bootstrap';
import ImgCar from '../pic/p4_bottom_car.svg';
import img1 from '../pic/p6_img1.svg';
import img2 from '../pic/p6_img2.svg';
import img3 from '../pic/p6_img3.svg';
import img4 from '../pic/p6_img4.svg';



const ScreenSix = () => {

   

  return (
    <>
    
    <div className={` ${c.screen_six}`}>
            
                <div className={`${c.p_six_text_large} ${c.biger_line_h}`}>
                    Ми раді запропонувати вам:
                </div>                               
           
            <div className={c.screen_six_bg_img_container}>

            {/* <div lassName={c.screen_six_img_sub_container}>  </div>  */}
                    <div className={c.inner_col}>
                    <Image src={img1} fluent/>
                        {/* <div className={c.screen_six_bg_img1}/> */}
                            <div className={c.screen_six_txt_box}>
                                <div className={c.screen_six_center_txt}>
                                Довгострокову оренду<br/> електромобілів в<br/> Одесі
                            </div>
                            </div> 
                    </div>
                    <div className={c.inner_col}>
                    <Image src={img2} fluent/>
                            <div className={c.screen_six_txt_box}>
                                <div className={c.screen_six_center_txt}>
                                Можливість<br/> викупу авто у власність<br/> орендаря                              
                            </div>
                        </div> 
                    </div>

                <div className={c.inner_col}>
                  <Image src={img3} fluent/>
                    {/* <div className={c.screen_six_bg_img3}/> */}
                      <div className={c.screen_six_txt_box}>
                        <div className={c.screen_six_center_txt}>
                            Надійні та правильно<br/> обслужені авто
                        </div>
                      </div> 
                </div>
                <div className={c.inner_col}>
                 <Image src={img4} fluent/>
                    {/* <div className={c.screen_six_bg_img4}/> */}
                        <div className={c.screen_six_txt_box}>
                            <div className={c.screen_six_center_txt}>
                            Якісний сервіс<br/> якому ви можете<br/> довіритись
                        </div>
                    </div> 
                </div>
            </div>


         <div className={` ${c.screen_four_bottom_img}`}>             
             <Image src={ImgCar} className={c.p4_img_car} fluid/> 
         </div>
    </div>
     
     </>
 )
}

export default ScreenSix
