import React from 'react';
import c from './ModalCall.module.css';
import {
    Container,
    Row,
    Col, 
    Modal   
  } from 'react-bootstrap';

const ModalHeader = (props) => {
    return (
        <Modal.Header closeButton className={c.bg}>
           <Container>                         
            <Row className={c.modal__form}>
              <Col>
               <span className={c.txt_lg}>
                 {props.title}
               </span>
              </Col>
            </Row>
           </Container> 
          </Modal.Header>
    )
}

export default ModalHeader
