import React, {useState, useEffect} from 'react';
import c from './css/Screen_Four.module.css';
import { Container } from 'react-bootstrap';
import Card from '../Components/Card';
import NoCar from '../Components/NoCar';
import useV3Firestore from "../Hooks/useV3Firestore";
//import axios from 'axios';


const ScreenFour = () => {

  const collection = 'cars';
  const {carsList} = useV3Firestore(collection);
  // console.log(carsList);
  const [alignCardsCenter, setAlignCardsCenter] = useState('c.screen_four');
  const [isAvailable, setIsAvailable] = useState(false);
  //const [availableCars, setAvailableCars] = useState([]);

    
      const availableCars = carsList.filter(function(car) {
        return car.available === 'yes'; 
      });
//const [test, setTest] = useState([]);
// useEffect(() => {
//   axios('http://localhost:8000/get_available_cars').then(res => {
//    // res.json(res.data);
//    setAvailableCars(res.data);
//   });
  
// }, []);
//console.log(availableCars, carsList);
  useEffect(() => {
    if (availableCars && availableCars.length > 0) {
      setIsAvailable(true); 
      if (availableCars.length > 3) {
        setAlignCardsCenter('c.screen_four2');
      }     
    } else {
      setIsAvailable(false);
    }

  }, [availableCars]);

  return (
    <>
    
    <Container fluid className={`mt-3 ${alignCardsCenter}`}>
            <div className={c.screen_four_text_box}>
                <div className={`${c.p_four_text_large} ${c.biger_line_h}`}>
                  Наявні авто
                </div>
                <div className={c.p_four_text_normal}>
                 Тільки в нас найкращі пропозиції для вас!
                </div>
                             
            </div>
            {isAvailable &&
              <div className={c.card_container}>
                {availableCars.map((car) => 
                
                  <Card
                    model={car.model} 
                    img={car.img[0]}
                    images={car.img}
                    id={car.id}
                    color={car.color}
                    year={car.year}
                    bat={car.bat}
                    cost={car.cost}
                    details={car.details}
                    fullDetails={car.fullDetails}
                  />              
                )}
              </div>
            }
            {!isAvailable &&
              <div className={c.no_car_container}>
                <NoCar />
              </div>
            }
        
    </Container>
     
     </>
 )
}

export default ScreenFour
