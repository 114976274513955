import React from 'react';
import { Alert } from 'react-bootstrap';
import c from './alert_msg.module.css'

const AlertMsg = (props) => {
  return (
    <Alert variant={props.variant} className={c.alert_container}>
     <span className={c.alert}>
      {props.text}
     </span>
   </Alert> 
  )
}

export default AlertMsg