import React, { useState, useEffect } from 'react';
import c from './ModalCall.module.css';
import btn from './btn.module.css';
import { addItemWithTrigers } from './Functions';
import { 
    Button, 
    Container,
    Modal  
} from 'react-bootstrap';
import AlertOnAction from './AlertOnAction';
import FooterButtons from './FooterButtons';
import ModalFormline from './ModalFormline';
import AlertDangerRound from './AlertDangerRound';
import AlertDangerTriangle from './AlertDangerTriangle';
import ModalHeader from './ModalHeader';
import RadioComp from './RadioComp';

const EditData = ( { cost, details, fullDetails, docID, avail } ) => {
 
const collection = 'cars';
const [showModalForm, setShowModalForm] = useState(false);

const [newCost, setNewCost] = useState(cost);
const [newDetails, setNewDetails] = useState(details);
const [newFullDetails, setNewFullDetails] = useState(fullDetails);
const [available, setAvailable] = useState(avail);

const [notice, setNotice] = useState(false);
const [newCostEmpty, setNewCostEmpty] = useState(false);
const [newDetailsEmpty, setNewDetailsEmpty] = useState(false);
const [newFullDetailsEmpty, setNewFullDetailsEmpty] = useState(false);
const [availableEmpty, setAvailableEmpty] = useState(false);

///////////////////////// Edit Item Block
/////// Edit Item Alerts
const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);
const [alertSuccess, setAlertSuccess] = useState(false);
/////////
const editItem = (data) => {
  addItemWithTrigers(data, collection, docID, setAddingItem, setDoneAdding, setError);
  //console.log(data, collection, docID);
  // fetch('http://localhost:8000/edit_car', {
  //   method: 'POST',
  //   body: JSON.stringify(data),
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // });
};

const hideModal = () => {
  setNewCost('');
  setNewDetails('');
  setNewFullDetails(''); 
  setAvailable('');
  setShowModalForm(false);
  setNotice(false);   
  setNewCostEmpty(false);
  setNewDetailsEmpty(false);
  setNewFullDetailsEmpty(false);
  setAvailableEmpty(false);
  setAddingItem(false);
  setDoneAdding(false);
  setError(false);
}; 

const dataAdded = () => {
  setAlertSuccess(true);
  setTimeout(() => setAlertSuccess(false), 2000 );
  setTimeout(() => setShowModalForm(false), 2300 ); 
  setTimeout(() => setDoneAdding(false), 2500 ); 
  hideModal();          
};

const checkForError = () => {
  if (addingItem) {
    setError(true);
    setAddingItem(false);
    setDoneAdding(false);
    setTimeout(() => setError(false), 4000 );
    return;
  } else if (doneAdding) {
    setError(false);
    return;
  }
};
useEffect(() => {
  if (!error && doneAdding) {
       dataAdded();      
  } if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
  } if (addingItem) {
    setTimeout(() => checkForError(), 30000 );
    return;
  }
// eslint-disable-next-line
}, [error, doneAdding, addingItem]);


const checkUpdate = () => {         
  if (newCost === '') {          
  setNewCostEmpty(true);
  setNotice(true);        
 } if (newDetails === '') {        
  setNewDetailsEmpty(true);
  setNotice(true);            
 } if (newFullDetails === '') {        
  setNewFullDetailsEmpty(true);
  setNotice(true);            
 } if (available === '') {        
  setAvailableEmpty(true);
  setNotice(true);            
 } if (!notice) {         
  amendData();
 } else {return;}
};
function amendData () {
  ///////////////////////////// Формируем данные
        const data = { 
          cost: newCost,
          details: newDetails,
          fullDetails: newFullDetails,
          available: available,
          docID: docID
        }; 
  /////////////////////////// Отправлем форму  
    editItem(data);    
}  


  return (
    <>
        <Button  
         className={` ${btn.rounded_btn} `} 
         variant={'outline-light'}  
         onClick={() => setShowModalForm(true)} 
       > 
         Редагувати
        </Button>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Редагування Запису'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             
               {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій редагування даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {alertSuccess ? (
                  <AlertOnAction 
                    header={'Запис змінено'}
                    />
             ) : (addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
                      <>
                  <AlertDangerRound
                    alertHeader={'Вкажіть вартість оренди'}
                    triger={newCostEmpty}
                    /> 
                 <ModalFormline 
                   header={'Вартість оренди на добу (від...)'} 
                   value={newCost}
                   type={'number'}
                   rows={1}
                   onChange={(data) => setNewCost(data)}                
                 />  

                  <AlertDangerRound
                    alertHeader={'Додайте опис'}
                    triger={newDetailsEmpty}
                    /> 
                 <ModalFormline 
                   header={'Опис авто'} 
                   value={newDetails}
                   rows={3}
                   type={'text'}
                   onChange={(data) => setNewDetails(data)}                
                 />  

                  <AlertDangerRound
                    alertHeader={'Додайте повний опис'}
                    triger={newFullDetailsEmpty}
                    /> 
                 <ModalFormline 
                   header={'Повний опис авто'} 
                   value={newFullDetails}
                   rows={4}
                   type={'text'}
                   onChange={(data) => setNewFullDetails(data)}                
                 /> 

                 <AlertDangerRound
                    alertHeader={'Авто наразі вільне?'}
                    triger={availableEmpty}
                    /> 
                 <ModalFormline 
                   header={'Авто наразі вільне?'} 
                   value={available.toUpperCase()}
                   rows={1}
                   type={'text'}
                   onChange={(data) => setAvailable(data)} 
                   disabled={true}               
                 /> 
                 <RadioComp 
                  lable1={'Так'}
                  value1={'yes'}
                  action1={() => setAvailable('yes')}
                  lable2={'Ні'}
                  value2={'no'}
                  action2={() => setAvailable('no')}
                 /> 

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, заповнить необхідні дані'}
                     />
                    </>))}                     
                  </>)}
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkUpdate}
            disabled={error || addingItem}
           />      
         </Modal>
    </>
  )
}

export default EditData