import React, { useRef, useState } from 'react';
import { Form, Button, Card, Container, Alert } from 'react-bootstrap';
import c from './Signup.module.css';
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";

export default function ForgotPassword() {

    const emailRef = useRef();
      
    const { resetPassword } = useAuth(); // Можно убрать - currentUser
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] =useState('');
    

    async function handleSubmit(e) {
        e.preventDefault();
        try{
            setError('');
            setMessage('');
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage('Инструкции по востановлению пароля высланны на ваш email адресс');            
        } catch {
            setError('Ошибка восстановления учетной записи пользователя');
            console.log(error);
        }
        setLoading(false);

        

    }

    return (
        <>
       

        <Container fluid className={c.container} >
           
        <Card className={c.card}>
            <Card.Body>
                <h4 className="text-center mb-4" >
                    ВОССТАНОВИТЬ ПАРОЛЬ
                </h4>

                     {error && <Alert variant='danger'>{error}</Alert>}
                     {message && <Alert variant='success'>{message}</Alert>}

                <Form onSubmit={handleSubmit}>
                     <Form.Group id="email">
                         <Form.Label >Email</Form.Label>
                         <Form.Control 
                            type="email" 
                            ref={emailRef}
                            required />
                     </Form.Group>
                     
                        <br />
                        <br />                     
                     <Button disabled={loading} className="w-100" type="submit" variant='success outlined'>
                        Востановить пароль
                     </Button>
                </Form>
            </Card.Body>     
          </Card>

          <div className="w-100 text-center mt-4" >
               Вспомнили пароль?  
               <div className="mt-2">
                 <Link to="/login" className="mt-2">
                   Войти в аккаунт
                 </Link>
               </div>
           </div>      
        </Container>
        </>
    )
}
